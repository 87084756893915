'use client';

import React, { useState, useEffect } from 'react';

import Container from '@/components/shared/Container';
import Image from 'next/image';
import Typography from '@/components/shared/Typography';
import Input from '@/components/shared/forms/Input';
import Textarea from '@/components/shared/forms/Textarea';
import Select from '@/components/shared/forms/Select';
import Button from '@/components/shared/forms/Button';
import { getFileUrl, t } from '@/lib/helpers/directus';
import cx from 'classnames';
import { createEntry, getToursData, getCruises } from '@/lib/helpers/directus';
import { useSearchParams } from 'next/navigation'

import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";

const Form = ({ form, linked_item_id = null, params, className }) => {
    const [token, setToken] = useState("");
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

    const settings = params.settings;

    const [data, setData] = useState({});
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const [tours, setTours] = useState(null);
    const [cruises, setCruises] = useState(null);

    const [toursInputVisible, setToursInputVisible] = useState(false);
    const [cruisesInputVisible, setCruisesInputVisible] = useState(false);

    const searchParams = useSearchParams()
    const infoBase64 = searchParams.get('info');
    const info = infoBase64 ? JSON.parse(atob(infoBase64)) : {};

    useEffect(() => {
        const tempData = {};

        if (form.name === 'booking') {
            getToursData(params.locale).then((res) => {
                res = res.map((option, index) => {
                    option = {
                        ...option.translated_content,
                        ...option,
                        label: option.translated_content.title,
                        value: option.id
                    };
        
                    return option;
                });

                setTours(res);
            }).catch((err) => {
                console.error('Form error', err);
            });
    
            getCruises(params.locale).then((res) => {
                res = res.map((option, index) => {
                    option = {
                        ...option.translated_content,
                        ...option,
                        label: option.translated_content.title,
                        value: option.id
                    };

                    return option;
                });

                setCruises(res);
            }).catch((err) => {
                console.error('Form error', err);
            });
        } else if (form.key.includes('brochure')) {
            if (form.key.includes('-brochure')) {
                if (linked_item_id) {
                    tempData.linked_item_id = linked_item_id;
                }

                if (form.key.includes('tour')) {
                    tempData.linked_item = 'tours';
                } else if (form.key.includes('cruise')) {
                    tempData.linked_item = 'cruises';
                }
            }
            
            tempData.type = form.name;

            setData({
                ...data,
                ...tempData
            });
        }

    }, []);

    useEffect(() => {
        const tempData = {};

        if (form.name === 'booking') {
            if (info && info.collection) {
                if (info.collection === 'tours' && tours) {
                    setToursInputVisible(true);
                    setCruisesInputVisible(false);

                    tempData.linked_item = 'tours';

                    if (info['item-id']) {
                        tempData.linked_item_id = info['item-id'];
                    }
                } else if (info.collection === 'cruises' && cruises) {
                    setToursInputVisible(false);
                    setCruisesInputVisible(true);

                    tempData.linked_item = 'cruises';

                    if (info['item-id']) {
                        tempData.linked_item_id = info['item-id'];
                    }
                } else {
                    setToursInputVisible(false);
                    setCruisesInputVisible(false);
                }
                
                setData({
                    ...data,
                    ...tempData
                });
            }
            
        }

    }, [tours, cruises]);

    if (!form) {
        return null;
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const allFields = e.target.querySelectorAll('input, select, textarea');
        for (const field of allFields) {
            if (field.required) {
                console.log('field', field);

                const fieldElementType = field.tagName;
                console.log('fieldElementType', fieldElementType);

                switch (fieldElementType) {
                    case "SELECT":
                        if (field.value === 'select' || !field.value) {
                            setError(t(params, 'form_error'));
                            return;
                        }
                        break;
                
                    case "INPUT":
                        if (field.type === 'checkbox') {
                            if (!field.checked) {
                                setError(t(params, 'form_error'));
                                return;
                            }
                        } else if (!field.value) {
                            setError(t(params, 'form_error'));
                            return;
                        }
                        break;
                
                    case "TEXTAREA":
                        if (!field.value) {
                            setError(t(params, 'form_error'));
                            return;
                        }
                        break;
                
                    default:
                        if (!field.value) {
                            setError(t(params, 'form_error'));
                            return;
                        }
                        break;
                }
            }
        }

        let localeCode = 'en-US';
        if (params.langByLocale && params.langByLocale.code) {
            localeCode = params.langByLocale.code;
        }
        data.locale = localeCode;

        if (data.linked_item) {
            createEntry(e.target.id, data, data.linked_item, data.linked_item_id, params, token).then((res) => {
                setSuccess(res);

                e.target.reset();
            }).catch((err) => {
                setRefreshReCaptcha(!refreshReCaptcha);

                console.error('Form error', err);
                setError(err);

                e.target.reset();
            });
        } else {
            createEntry(e.target.id, data, info.collection, info['item-id'], params, token).then((res) => {
                setSuccess(res);

                e.target.reset();
            }).catch((err) => {
                setRefreshReCaptcha(!refreshReCaptcha);

                console.error('Form error', err);
                setError(err);

                e.target.reset();
            });
        }
    };

    const setTokenFunc = (getToken) => {
        if (!token) {
            setToken(getToken);
        }
    };

    const handleChange = (e) => {
        if (e.target.name === 'linked_item') {
            if (e.target.value === 'tours') {
                setToursInputVisible(true);
                setCruisesInputVisible(false);

                delete data.linked_item_id;
            } else if (e.target.value === 'cruises') {
                setToursInputVisible(false);
                setCruisesInputVisible(true);

                delete data.linked_item_id;
            } else {
                setToursInputVisible(false);
                setCruisesInputVisible(false);

                delete data.linked_item_id;
            }
        }

        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    };

    return (
        <Container className={cx('-mt-44 z-10 relative scroll-mt-40 pb-20', className)} id={`request-${form.key}`}>
            {/* {JSON.stringify(data)} */}

            <form id={form.id} onSubmit={handleSubmit} className=' lg:w-2/3 mx-auto rounded-4xl bg-secondary-100 flex flex-col gap-y-8 p-8 items-center'>
                <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_APP_RECAPTCHA_CLIENT_KEY}>
                    <GoogleReCaptcha
                        className="google-recaptcha-custom-class"
                        onVerify={setTokenFunc}
                        refreshReCaptcha={refreshReCaptcha}
                    />
                </GoogleReCaptchaProvider>

                <div className='w-28 h-28 -mt-20'>
                    {settings.logo_no_text && (
                        <Image className="w-auto h-full mx-auto" src={getFileUrl(settings.logo_no_text)} alt={'Logo'} width={200} height={50} />
                    )}
                </div>

                {!form.translated_content?.title && !form.translated_content?.short_description ? null : (
                    <div className='flex flex-col items-center'>
                        {form.translated_content.title && (
                            <Typography variant='h3' className='w-fit uppercase text-primary-800 font-bold'>
                                {form.translated_content?.title}
                            </Typography>
                        )}

                        {form.translated_content?.short_description && (
                            <Typography variant='p' className='w-fit max-w-lg text-primary-800 text-center'>
                                {form.translated_content?.short_description}
                            </Typography>
                        )}
                    </div>
                )}

                {form.groups && form.groups.map((group, groupIndex) => (
                    <React.Fragment key={groupIndex}>
                        <div className={`w-full flex flex-col ${group.data.group_is_hidden ? 'hidden' : ''}`}>
                            {(group.data.translated_content && group.data.translated_content.label && form.show_group_labels) && (
                                <Typography variant='p' className='w-fit text-primary-800'>
                                    {group.data.translated_content.label}
                                </Typography>
                            )}

                            <div className='w-full bg-primary-800 rounded-md p-2 '>
                                {group.data.fields && group.data.fields.map((field, fieldIndex) => {
                                    const isLastField = fieldIndex == group.data.fields.length - 1;
                                    const isBeforeLastField = fieldIndex == group.data.fields.length - 2;

                                    let classname = `border-secondary-100 w-full`

                                    if (field.data.width === 'full') {
                                        classname += ` border-b last:border-b-0`
                                    } else { // half
                                        classname += ` lg:w-1/2`
                                        if (isLastField || isBeforeLastField) {
                                            if (isLastField) {
                                                classname += ' border-b-0'
                                            } else if (isBeforeLastField) {
                                                // check if last field is full width or not
                                                if (group.data.fields[fieldIndex + 1].data.width === 'full') {
                                                    classname += ' border-b'
                                                } else {
                                                    classname += ' border-b lg:border-b-0'
                                                }
                                            }
                                        } else {
                                            classname += ' border-b'
                                        }
                                    }

                                    const spreadProps = {}
                                    if (field.data.readonly) {
                                        spreadProps.readOnly = true
                                        spreadProps.disabled = true

                                        if (field.data.key === 'info' && info) {
                                            if(field.data.type && field.data.type !== 'dropdown' && field.data.type !== 'textarea') {
                                                spreadProps.value = JSON.stringify(info)
                                            }
                                        }
                                    }

                                    return (
                                        <React.Fragment key={fieldIndex}>
                                            {(field.data.type && field.data.type == 'textarea') ? (
                                                <Textarea {...spreadProps} required={field.data.required} onChange={handleChange} name={field.data.key} rows={field.data.row_count} placeholder={field.data.translated_content?.placeholder ?? ''} className={classname} />
                                            ) : (field.data.type && field.data.type == 'dropdown') ? (
                                                field.data.key === 'linked_item' ? (
                                                    <>
                                                        <Select {...spreadProps} value={info && info.collection ? info.collection : 'select'} required={field.data.required} onChange={handleChange} name={field.data.key} options={field.data.options ?? []} placeholder={field.data.translated_content?.placeholder ?? ''} className={`${classname} cursor-pointer`} params={params} />

                                                        {toursInputVisible && (
                                                            <Select {...spreadProps} value={info && info['item-id'] ? info['item-id'] : 'select'} required={field.data.required} onChange={handleChange} name='linked_item_id' options={tours} placeholder={field.data.translated_content?.placeholder ?? ''} className={`${classname} cursor-pointer`} params={params} />
                                                        )}

                                                        {cruisesInputVisible && (
                                                            <Select {...spreadProps} value={info && info['item-id'] ? info['item-id'] : 'select'} required={field.data.required} onChange={handleChange} name='linked_item_id' options={cruises} placeholder={field.data.translated_content?.placeholder ?? ''} className={`${classname} cursor-pointer`} params={params} />
                                                        )}
                                                    </>
                                                ) : (
                                                    <Select {...spreadProps} required={field.data.required} onChange={handleChange} name={field.data.key} options={field.data.options ?? []} placeholder={field.data.translated_content?.placeholder ?? ''} className={`${classname} cursor-pointer`} params={params} />
                                                )
                                            ) : (
                                                <Input {...spreadProps} required={field.data.required} onChange={handleChange} name={field.data.key} placeholder={field.data.translated_content?.placeholder ?? ''} type={field.data.type ?? 'text'} className={classname} />
                                            )}
                                        </React.Fragment>
                                    )
                                })}
                            </div>

                            {(groupIndex == form.groups.length - 1) && (
                                (success || error) && (
                                    <div>
                                        {success && (
                                            <Typography variant='p' className='text-green-500 font-semibold ml-3'>
                                                {success}
                                            </Typography>
                                        )}
                
                                        {error && (
                                            <Typography variant='p' className='text-red-500 font-semibold ml-3'>
                                                {error}
                                            </Typography>
                                        )}
                                    </div>
                                )
                            )}
                        </div>
                    </React.Fragment>
                ))}

                {form.require_consent_checkbox == true && (
                    <div className='w-full'>
                        <Input id='consent_checkbox' required={true} type='checkbox' label={t(params, 'consent_checkbox_description')} params={params} />
                    </div>
                )}

                <div>
                    <Button variant={`${form.button_variant ? form.button_variant : 'tertiary'}`}>
                        {form && form.translated_content && form.translated_content.button_text ? form.translated_content.button_text : 'Submit'}
                    </Button>
                </div>
            </form>
        </Container>
    );
}

export default Form